import CartProvider from '@jetshop/core/components/Query/CartProvider';
import t from '@jetshop/intl';
import { DrawerTrigger } from '@jetshop/ui/Modal/Drawer/DrawerTrigger';
import { styled } from 'linaria/react';
import { css } from 'linaria';
import React, { Fragment } from 'react';
import { ReactComponent as Cart } from '../../svg/MenuCart.svg';
import Badge from '../ui/Badge';
import cartQuery from './CartQuery.gql';
import { theme } from '../Theme';
import { FindifyCartTracker } from '../Findify/Findify';

const Button = styled('button')`
  padding: 0;
  background: transparent;
  color: inherit;
  border: 0;
  outline: none;
  ${theme.below.lg} {
    span {
      display: none;
    }
  }
`;

const cartButtonWrapper = css`
  position: relative;
  .badge {
    background: ${theme.colors.gold};
    position: absolute;
    top: 0px;
    left: 10px;
    pointer-events: none;
    div {
      color: white;
      font-size: 10px;
    }
    ${theme.below.lg} {
      top: -4px;
      left: -1px;
    }
  }
`;
function CartButton({ target, itemsInCart, className, modals }) {
  return (
    <div className={cartButtonWrapper}>
      <Button
        className={className}
        data-testid="cart-button"
        aria-label="Shopping Cart"
        onClick={() => {
          modals && modals[0]?.hideTarget();
          if (target.isOpen) {
            target.hideTarget();
          } else {
            target.showTarget();
          }
        }}
      >
        <Cart className="badge-svg-wrapper" />
        <span>{t('Cart')}</span>
      </Button>
      {itemsInCart > 0 && <Badge text={itemsInCart} />}
    </div>
  );
}

function CartButtonFlyout({ className, modals }) {
  return (
    <Fragment>
      <CartProvider query={cartQuery}>
        {result => {
          // Set items in cart to the API result.
          // If the result is undefined, fall back to 0
          const itemsInCart = result?.data?.cart?.totalQuantity || 0;

          return (
            <>
              <DrawerTrigger preventOverflow={true} id="cart-drawer">
                {drawer => (
                  <>
                    <CartButton
                      className={className}
                      target={drawer}
                      itemsInCart={itemsInCart}
                      modals={modals}
                      aria-label="Shopping Cart"
                    />
                  </>
                )}
              </DrawerTrigger>
              <FindifyCartTracker cartData={result?.data?.cart} />
            </>
          );
        }}
      </CartProvider>
    </Fragment>
  );
}

export default CartButtonFlyout;
