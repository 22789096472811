import { DrawerTrigger } from '@jetshop/ui/Modal/Drawer/DrawerTrigger';
import { useProductList } from '@jetshop/core/hooks/ProductList';
import React, { useEffect } from 'react';
import { ReactComponent as HeartSVG } from '../../svg/Heart.svg';
import Badge from '../ui/Badge';
import { styled } from 'linaria/react';
import t from '@jetshop/intl';
import { theme } from '../Theme';

const Button = styled('button')`
  padding: 0;
  background: transparent;
  color: inherit;
  border: 0;
  outline: none;
  .badge-svg-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 1px;
    svg {
      align-self: center;
    }
    span {
      ${theme.below.lg} {
        display: none;
      }
    }
  }
  .badge {
    background: ${theme.colors.gold};
    position: absolute;
    top: -1px !important;
    left: 7px;
    pointer-events: none;
    div {
      color: white;
      font-size: 10px;
    }
    ${theme.below.lg} {
      top: -5px !important;
      left: -5px !important;
    }
  }
`;

function FavouritesButton({ target, count, className, modals }) {
  return (
    <div>
      <Button
        className={className}
        data-testid="favourites-button"
        aria-label="Favourites"
        onClick={() => {
          modals && modals[0]?.hideTarget();
          if (target.isOpen) {
            target.hideTarget();
          } else {
            target.showTarget();
          }
        }}
      >
        <div className="badge-svg-wrapper">
          {count > 0 && <Badge text={count} />}
          <HeartSVG />
          <span>{t('Favourites')}</span>
        </div>
      </Button>
    </div>
  );
}

function FavouritesButtonFlyout({ className, listId, modals }) {
  const { count, toggle, inList } = useProductList();
  // const topPosition = useHeaderHeight();

  // ADD FAVOURITES TO GLOBAL SCOPE FOR FINDIFY
  useEffect(() => {
    const toggleFavourite = articleNumber => {
      toggle(articleNumber, {
        variantArticleNumber: null,
        productName: ''
      });
    };
    const isFavourite = articleNumber => {
      return inList(articleNumber, {
        variantArticleNumber: null
      });
    };
    window.findifyHelpers = window.findifyHelpers || {};
    window.findifyHelpers.isFavourite = isFavourite;
    window.findifyHelpers.toggleFavourite = toggleFavourite;
  });

  return (
    <DrawerTrigger
      preventOverflow={true}
      id="favourites-drawer"
      // coverStyles={{ top: topPosition }}
    >
      {drawer => (
        <FavouritesButton
          data-testid="favourites-button"
          id="favourites-button"
          className={className}
          target={drawer}
          count={count}
          modals={modals}
          aria-label="Favourites"
        />
      )}
    </DrawerTrigger>
  );
}

export default FavouritesButtonFlyout;
