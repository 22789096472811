import loadable from '@loadable/component';

import { BoxRow, BoxRowItem } from './BoxRow';
import { Button } from './Button';
import { BasicButton } from './BasicButton';
import { ColoredTextRow } from './ColoredTextRow';
// import { ImageHero } from './Hero';
import { JournalHero } from './JournalHero';
import { LargeIconUspItem, LargeIconUspRow } from './LargeIconUspRow';
import { LinkRow, LinkRowItem } from './LinkRow';
// import {
//   MultipleCategoryRow,
//   MultipleCategoryRowItem
// } from './MultipleCategoryRow';
import { Spacer } from './Spacer';
import { Text } from './Text';
import { CampaignBar, CampaignBarItem } from './CampaignBar';
import { Usps } from './Usps';
import { HtmlContent } from './HtmlContent';
import {
  PaymentAndDeliveryRow,
  PaymentAndDeliveryRowItem,
  TryggEHandel
} from './PaymentAndDeliveryRow';
import { GoldDotHtmlContent } from './GoldDotHtmlContent';
import { Stack, JournalStack, SizeGuideStack } from './Stack';
import { JournalRow, JournalImage } from './JournalRow';
import { JournalPush } from './JournalPush';
import {
  FooterRow,
  FooterRowContacts,
  FooterRowItem,
  FooterRowNewsLetter,
  SocialMediaLinks
} from './FooterRow';
import { ShopTheLookItem, ShopTheLook } from './ShopTheLook';
import {
  ShopTheLookSingleProduct,
  ShopTheLookSingleProductItem
} from './ShopTheLookSingleProduct';
import { FindifyComponent } from '../Findify/FindifyComponent';
import { JournalImages, JournalImagesItem } from './JournalImages';
import TextBox from './TextBox';
import { MembersClub } from './MembersClub';
import { JournalMenu } from './JournalMenu';
import { PreviewArticleRow } from './PreviewArticleRow';
import { ArticleItem } from './ArticleItem';
import { FlowboxComponent } from '../Flowbox/FlowboxPage';
import { BoxPush, BoxPushItem } from './BoxPush';
import { VideoHero } from './VideoHero';
import { JournalProductRow, JournalProductRowItem } from './JournalProductRow';
// import { ImageBox, ImageBoxItem } from './ImageBox';
import {
  MultipleCategoryRow,
  MultipleCategoryRowItem
} from './MultipleCategoryRow';
import { NewImageBox, NewImageBoxItem } from './NewImageBox';
import { ImageCategoryBox, ImageCategoryBoxItem } from './ImageCategoryBox';
import { NewImageHero } from './NewImageHero';

export const contentEditorComponents = {
  SPACER: Spacer,
  BUTTON: Button,
  BASICBUTTON: BasicButton,
  TEXT: Text,
  MULTIPLECATEGORYROW: MultipleCategoryRow,
  MULTIPLECATEGORYROWITEM: MultipleCategoryRowItem,
  BOXROW: BoxRow,
  BOXROWITEM: BoxRowItem,
  LINKROW: LinkRow,
  LINKROWITEM: LinkRowItem,
  CAMPAIGNBAR: CampaignBar,
  CAMPAIGNBARITEM: CampaignBarItem,
  LARGEICONUSPROW: LargeIconUspRow,
  LARGEICONUSPROWITEM: LargeIconUspItem,
  COLOREDTEXTROW: ColoredTextRow,
  TEXTBOX: TextBox,
  HTMLSTACK: Stack,
  HTMLCONTENT: HtmlContent,
  FINDIFY: FindifyComponent,
  PREVIEWARTICLEROW: PreviewArticleRow,
  ARTICLEITEM: ArticleItem,
  FLOWBOX: FlowboxComponent,
  BOXPUSH: BoxPush,
  BOXPUSHITEM: BoxPushItem,
  VIDEOHERO: VideoHero,
  IMAGEBOX: NewImageBox,
  IMAGEBOXITEM: NewImageBoxItem,
  IMAGECATEGORYBOX: ImageCategoryBox,
  IMAGECATEGORYBOXITEM: ImageCategoryBoxItem,
  IMAGEHERO: NewImageHero
};

export const cartUspComponents = {
  USPS: Usps,
  USPITEM: HtmlContent,
  PAYMENTANDDELIVERYROW: PaymentAndDeliveryRow,
  PAYMENTANDDELIVERYROWITEM: PaymentAndDeliveryRowItem,
  TRYGGEHANDEL: TryggEHandel
};

export const cartPaymentComponents = {
  PAYMENTANDDELIVERYROW: PaymentAndDeliveryRow,
  PAYMENTANDDELIVERYROWITEM: PaymentAndDeliveryRowItem,
  TRYGGEHANDEL: TryggEHandel
};

export const productPageUspComponents = {
  USPS: Usps,
  USPITEM: GoldDotHtmlContent
};

export const productInfoComponents = {
  HTMLSTACK: Stack,
  HTMLCONTENT: HtmlContent,
  SPACER: Spacer
};

export const sizeGuideComponents = {
  HTMLSTACKRAW: SizeGuideStack,
  HTMLCONTENTRAW: HtmlContent,
  SPACER: Spacer
};

export const JournalComponents = {
  JOURNALHERO: JournalHero,
  JOURNALROW: JournalRow,
  JOURNALIMAGE: JournalImage,
  JOURNALPUSH: JournalPush,
  JOURNALIMAGES: JournalImages,
  JOURNALIMAGESITEM: JournalImagesItem,
  JOURNALMENU: JournalMenu,
  ARTICLEITEM: ArticleItem,
  INGRESS: HtmlContent,
  TEXT: Text,
  HTMLSTACK: JournalStack,
  HTMLCONTENT: HtmlContent,
  SPACER: Spacer,
  MULTIPLECATEGORYROW: MultipleCategoryRow,
  MULTIPLECATEGORYROWITEM: MultipleCategoryRowItem,
  SHOPTHELOOKITEM: ShopTheLookItem,
  SHOPTHELOOK: ShopTheLook,
  SHOPTHELOOKSINGLEPRODUCT: ShopTheLookSingleProduct,
  SHOPTHELOOKSINGLEPRODUCTITEM: ShopTheLookSingleProductItem,
  VIDEOHERO: VideoHero,
  JOURNALPRODUCTROW: JournalProductRow,
  JOURNALPRODUCTROWITEM: JournalProductRowItem
};

export const footerComponents = {
  FOOTERROW: FooterRow,
  FOOTERROWITEM: FooterRowItem,
  FOOTERROWCONTACTS: FooterRowContacts,
  FOOTERROWNEWSLETTER: FooterRowNewsLetter,
  SOCIALMEDIALINKS: SocialMediaLinks,
  PAYMENTANDDELIVERYROW: PaymentAndDeliveryRow,
  PAYMENTANDDELIVERYROWITEM: PaymentAndDeliveryRowItem,
  TRYGGEHANDEL: TryggEHandel
};

export const membersClubComponents = {
  MEMBERSPUSH: MembersClub,
  BUTTON: Button,
  BASICBUTTON: BasicButton,
  SPACER: Spacer
};
