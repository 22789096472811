import { useQuery } from '@apollo/react-hooks';
import { styled } from 'linaria/react';
import React, { useState } from 'react';
import 'slick-carousel/slick/slick.css';
import { ProductCard } from '../CategoryPage/ProductCard';
import MaxWidth from '../Layout/MaxWidth';
import { SliderWithButtons } from '../ui/SharedSlider';
import multipleCategoryRowQuery from './MultipleCategoryRowQuery.gql';
import { useInView } from 'react-intersection-observer';
import { theme } from '../Theme';

const MultipleCategoryRowWrapper = styled(MaxWidth)`
  padding: 0;

  h2 {
    text-align: center;
    color: #000;
    text-align: center;
    font-family: ${theme.fonts.secondary};
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 41px; /* 146.429% */
  }
`;

const CategoryNavigator = styled('div')`
  margin-top: 14px;
  display: flex;
  justify-content: center;
  margin-bottom: 1.7rem;

  .backround-plate {
    display: flex;
    background: #f7f7f7;
    border-radius: 43px;
    height: 36px;
    align-items: center;
  }
`;

const CategoryItemButton = styled('div')`
  position: relative;
  cursor: pointer;
  height: 36px;
  align-items: center;
  background: #f7f7f7;
  display: flex;
  padding: 0 20px;
  border-radius: 43px;
  border: 3px solid #f7f7f7;
  transition: all 0.2s ease-in-out;

  &.active {
    background: #f1eae0;
    border: 3px solid #f7f7f7;
  }
`;

const ProductSliderWrapper = styled('div')``;

const calculatedActivePath = (children, activeCategory) => {
  return children[activeCategory]?.props?.category?.value?.primaryRoute?.path;
};

export const MultipleCategoryRow = ({ title, children }) => {
  const [showed, setShowed] = useState(false);
  const [ref, inView] = useInView();
  if (inView && !showed) setShowed(true);

  const [activeCategory, setActiveCategory] = useState(0);
  let categoryResult = useQuery(multipleCategoryRowQuery, {
    variables: {
      orderBy: 'PUBLISH_DATE',
      orderByDirection: 'DESCENDING',
      pathname: calculatedActivePath(children, activeCategory)
    },
    errorPolicy: 'ignore'
  });

  if (!calculatedActivePath(children, activeCategory)) {
    return null;
  }

  const deskstopSlides =
    categoryResult?.data?.products < 5 ? categoryResult?.data?.products : 5;
  const mobileSlides =
    categoryResult?.data?.products < 2 ? categoryResult?.data?.products : 2;

  return (
    <MultipleCategoryRowWrapper ref={ref}>
      <h2>{title?.value}</h2>
      <CategoryNavigator>
        <div className="backround-plate">
          {children.map((child, i) => (
            <CategoryItemButton
              key={i}
              className={activeCategory === i ? 'active' : null}
              onClick={() => {
                setActiveCategory(i);
              }}
            >
              {child}
            </CategoryItemButton>
          ))}
        </div>
      </CategoryNavigator>
      <ProductSliderWrapper>
        <SliderWithButtons
          desktopSlides={deskstopSlides}
          mobileSlides={mobileSlides}
          arrowTopPosition={'38%'}
        >
          {categoryResult?.data?.route?.object?.products?.result?.map(
            product => (
              <ProductCard
                key={product.articleNumber}
                product={product}
                as="div"
              />
            )
          )}
        </SliderWithButtons>
      </ProductSliderWrapper>
    </MultipleCategoryRowWrapper>
  );
};

const CategoryName = styled('p')`
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  letter-spacing: 0.02em;
`;

export const MultipleCategoryRowItem = ({ name }) => {
  return <CategoryName>{name?.value}</CategoryName>;
};
