import React from 'react';
import t from '@jetshop/intl';
import { styled } from 'linaria/react';
import { ReactComponent as Search } from '../../../svg/MenuSearch.svg';
import { theme } from '../../Theme';

const Button = styled('button')`
  margin-bottom: 8px;
  background: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: inherit;
  font-size: 0.75rem;
  :hover {
    color: ${theme.colors.black};
    svg {
      use {
        fill: ${theme.colors.black};
      }
    }
  }
  svg {
    margin: 4px;
    margin-bottom: 0;
    height: 27px;
    width: 23px;
    use {
      fill: #878787;
    }
  }
  ${theme.below.lg} {
    z-index: 2;
    margin-left: 5px;
    margin-bottom: 0px;
    flex-direction: row;
    span {
      display: none;
    }
  }
`;

function SearchButton(props) {
  const { setSearchOpen, searchOpen, ...rest } = props;
  return (
    <Button
      onClick={() => setSearchOpen(!searchOpen)}
      {...rest}
      aria-label="Search"
    >
      <Search />
      <span> {t('Search')}</span>
    </Button>
  );
}

export default SearchButton;
