import { Below } from '@jetshop/ui/Breakpoints';
import { styled } from 'linaria/react';
import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as FJLogo } from './LOGOWTAG.svg';

const StoreName = styled('h2')`
  font-size: 30px;
  font-weight: bold;
  line-height: normal;
  text-align: center;
  text-decoration: none;
  color: inherit;
  align-self: center;
  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: inherit;
  }
`;

const Logo = ({ searchOpen }) => (
  <Below breakpoint="md">
    {matches =>
      matches ? (
        <>
          {!searchOpen && (
            <StoreName>
              <Link to="/" aria-label="Ferner Jacobsen Home">
                <FJLogo />
              </Link>
            </StoreName>
          )}
        </>
      ) : (
        <StoreName>
          <Link to="/" aria-label="Ferner Jacobsen Home">
            <FJLogo />
          </Link>
        </StoreName>
      )
    }
  </Below>
);

export { Logo };
