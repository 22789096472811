import React, { useContext } from 'react';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import { styled } from 'linaria/react';
import { theme } from '../../Theme';
import { DrawerTrigger } from '@jetshop/ui/Modal/Drawer/DrawerTrigger';
import { MenuButton } from './Header';
import { ReactComponent as Menu } from '../../../svg/Menu.svg';
import { ReactComponent as Person } from '../../../svg/MenuProfile.svg';
import SearchButton from './SearchButton';
import { LogoSearchBar } from './LogoSearchBar';
import { Link } from 'react-router-dom';
import FavouriteButton from '../../ProductList/FavouriteCount';
import CartButton from '../../Cart/CartButton';
import ModalContext from '@jetshop/ui/Modal/ModalContext';

const MobileHeaderWrapper = styled('div')`
  display: none;
  ${theme.below.xl} {
    display: flex;
    width: 100%;
    height: 64px;
  }
`;

const MyPagesLink = styled(Link)`
  display: flex;
  align-items: center;
  svg {
    margin: 4px;
    margin-bottom: 0;
    height: 18px;
  }
`;

const CartWrapper = styled('div')`
  ${theme.below.lg} {
    .badge {
      right: -5px;
      width: 16px;
      height: 16px;
      font-size: 10px;
    }
  }
`;

const FavWrapper = styled('div')`
  font-size: 0.75rem;
  .header-button {
    margin: 0;
    padding: 0;
  }
  ${theme.below.lg} {
    .badge {
      right: -5px;
      width: 16px;
      height: 16px;
      font-size: 10px;
    }
  }
`;

const Column = styled('div')`
  display: flex;
  justify-content: space-between;
  flex: 1;
  align-items: center;

  ${theme.below.lg} {
    display: flex;
    flex: 1;
    svg {
      height: 20px;
    }

    &.right {
      justify-content: flex-end;
      svg {
        height: 20px !important;
        width: 20px !important;
      }
      padding-right: 0;
      button,
      > * {
        margin-left: 0.2rem !important;
      }
    }

    &.left {
      justify-content: flex-start;
      svg {
        height: 20px;
        width: 20px;
      }
      .header-button {
        svg {
          height: 23px;
          width: 23px;
          margin-right: 5px;
        }
      }
    }

    .header-search {
      :hover,
      :focus {
        outline: none;
      }
      svg {
        margin: 0;
      }
      span {
        display: none;
      }
    }
  }
`;

export const MobileHeader = ({ data, searchOpen, setSearchOpen, loggedIn }) => {
  const { routes } = useShopConfig();
  const { modals } = useContext(ModalContext);

  return (
    <MobileHeaderWrapper id="site-header">
      <Column className={searchOpen ? 'left open' : ' left closed'}>
        <DrawerTrigger preventOverflow={true} id="menu-drawer">
          {drawer => (
            <MenuButton
              onClick={drawer.isOpen ? drawer.hideTarget : drawer.showTarget}
            >
              <div className="header-button">
                <Menu />
              </div>
            </MenuButton>
          )}
        </DrawerTrigger>
        <SearchButton
          className="header-search"
          onClick={() => setSearchOpen(!searchOpen)}
          searchOpen={searchOpen}
        />
      </Column>
      <LogoSearchBar searchOpen={searchOpen} setSearchOpen={setSearchOpen} />
      <Column className="right desktopmenu">
        <MyPagesLink
          className="header-button"
          to={routes.myPages.path}
          aria-label={'Min side'}
        >
          <Person />
        </MyPagesLink>
        <FavWrapper>
          <FavouriteButton modals={modals} className="header-button" />
        </FavWrapper>
        <CartWrapper>
          <CartButton modals={modals} className="header-button" />
        </CartWrapper>
      </Column>
    </MobileHeaderWrapper>
  );
};
