import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Above } from '@jetshop/ui/Breakpoints';
import Image from '@jetshop/ui/Image/Image';
import { cx } from 'linaria';
import { styled } from 'linaria/react';
import { theme } from '../Theme';
import {
  filterChildrenByKey,
  setVariableFromProp
} from '../../utils/HelpFunctions';

const criticalCss = `
  .hero-wrapper {
    width: 100%;
    position: relative;
  }

  .content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    width: 25%;
    min-width: 500px;
  }
`;

export const HeroWrapper = styled('div')`
  width: 100%;
  position: relative;

  h1,
  h2 {
    color: #000;
    font-family: ${theme.fonts.secondary};
    font-size: 60px;
    font-style: normal;
    font-weight: 300;
    line-height: 56px; /* 93.333% */
    text-transform: none;
    margin-bottom: 10px;
  }

  p {
    color: #000;
    font-family: ${theme.fonts.primary};
    font-size: 16px;
    font-style: normal;
    font-weight: 330;
    line-height: 24px; /* 150% */
    text-transform: none;
  }

  [data-flight-image-children] {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    position: absolute !important;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: auto;
    ${theme.below.lg} {
      padding: 0;
    }
  }

  &.TOP {
    [data-flight-image-children] {
      /* justify-content: flex-start; */
      justify-content: flex-start;
    }
  }
  &.MIDDLE {
    [data-flight-image-children] {
      /* align-items: center; */
      justify-content: center;
    }
  }

  &.BOTTOM {
    [data-flight-image-children] {
      /* align-items: flex-end; */
      justify-content: flex-end;
    }
  }

  &.LEFT {
    [data-flight-image-children] {
      /* justify-content: flex-start; */
      align-items: flex-start;

      h1,
      h2,
      p {
        text-align: left;
      }

      .button-row {
        justify-content: flex-start;
      }
    }
  }

  &.CENTER {
    [data-flight-image-children] {
      /* justify-content: center; */
      align-items: center;

      h1,
      h2,
      p {
        text-align: center;
      }

      .button-row {
        justify-content: center;
      }
    }
  }

  &.RIGHT {
    [data-flight-image-children] {
      /* justify-content: flex-end; */
      align-items: flex-end;

      h1,
      h2,
      p {
        text-align: right;
      }

      .button-row {
        justify-content: flex-end;
      }
    }
  }
`;

export const ContentWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  width: 25%;
  min-width: 400px;

  ${theme.below.lg} {
    min-width: 100%;
    padding: 40px 20px;

    .text-component {
      display: none;
    }
  }
`;

export const ButtonRow = styled('div')`
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 100%;
  flex-wrap: wrap;
  margin-top: 20px;
  gap: 10px;
`;

export const NewImageHero = ({
  desktopImageUrl,
  desktopImageAspect,
  mobileImageUrl,
  mobileImageAspect,
  contentVerticalAlignment,
  contentHorizontalAlignment,
  children
}) => {
  const deskImgUrl = setVariableFromProp(desktopImageUrl);
  const mobImgUrl = setVariableFromProp(mobileImageUrl);
  const deskImgAspect = setVariableFromProp(desktopImageAspect);
  const mobImgAspect = setVariableFromProp(mobileImageAspect);
  const vertAlign = setVariableFromProp(contentVerticalAlignment);
  const horAlign = setVariableFromProp(contentHorizontalAlignment);

  const filteredChildren = filterChildrenByKey(children, 'button', false);
  const buttonChildren = filterChildrenByKey(children, 'button', true);
  const imageSizes = [1 / 4, 1 / 2, 1, 1, 1];

  return (
    <>
      <Helmet>
        <style>{criticalCss}</style>
        <link
          rel="preload"
          href={deskImgUrl}
          as="image"
          media="(min-width: 1024px)"
        />
        <link
          rel="preload"
          href={mobImgUrl}
          as="image"
          media="(max-width: 1023px)"
        />
      </Helmet>
      <HeroWrapper className={cx(vertAlign, horAlign)}>
        <Above breakpoint="xl">
          {matches =>
            matches ? (
              <Image
                cover={true}
                aspect={deskImgAspect ?? '2880:1000'}
                src={deskImgUrl}
                sizes={imageSizes}
                critical={true}
              >
                <ContentWrapper>
                  {filteredChildren}
                  <ButtonRow className="button-row">{buttonChildren}</ButtonRow>
                </ContentWrapper>
              </Image>
            ) : (
              <Image
                cover={true}
                aspect={mobImgAspect ?? '750:550'}
                src={mobImgUrl}
                sizes={imageSizes}
                critical={true}
              >
                <ContentWrapper>
                  {filteredChildren}
                  <ButtonRow>{buttonChildren}</ButtonRow>
                </ContentWrapper>
              </Image>
            )
          }
        </Above>
      </HeroWrapper>
    </>
  );
};
