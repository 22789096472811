import { useProductList } from '@jetshop/core/hooks/ProductList';
import React, { useState } from 'react';
import { css } from 'linaria';
import { ReactComponent as HeartSVG } from '../../svg/NewHeart.svg';
import { theme } from '../Theme';

const favourite = css`
  button {
    background: transparent;
    svg {
      &.active {
        fill: ${theme.colors.gold};
        color: ${theme.colors.gold};
        path {
          stroke: ${theme.colors.gold} !important;
        }
      }
    }
  }
`;

export function Favourite({ product, variant, ...rest }) {
  const articleNumber = product.articleNumber;

  const selectedVariation = product.variant || variant;
  const variantArticleNumber = selectedVariation?.articleNumber;

  const { toggle, inList } = useProductList();

  function toggleWithoutBubble(e) {
    e.preventDefault();
    toggle(articleNumber, {
      variantArticleNumber,
      productName: product.name
    });
  }

  const isInList = inList(articleNumber, {
    variantArticleNumber
  });

  // Check if this component is inside an aria-hidden container
  const [isInAriaHidden, setIsInAriaHidden] = useState(false);

  // Use a ref to check if parent has aria-hidden
  const ref = React.useRef();

  React.useEffect(() => {
    if (ref.current) {
      // Check if any parent element has aria-hidden="true"
      let parent = ref.current.parentElement;
      while (parent) {
        if (parent.getAttribute('aria-hidden') === 'true') {
          setIsInAriaHidden(true);
          break;
        }
        parent = parent.parentElement;
      }
    }
  }, []);

  return (
    <div className={favourite} {...rest} ref={ref}>
      {!isInAriaHidden && (
        <button onClick={toggleWithoutBubble} aria-label="Add to favourites">
          <HeartSVG
            className={isInList ? 'active' : 'inactive'}
            style={{ height: '1em', width: '1em' }}
          />
        </button>
      )}
      {isInAriaHidden && (
        <div aria-hidden="true">
          <HeartSVG
            className={isInList ? 'active' : 'inactive'}
            style={{ height: '1em', width: '1em' }}
          />
        </div>
      )}
    </div>
  );
}
