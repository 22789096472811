import { useQuery } from '@apollo/react-hooks';
import { Below } from '@jetshop/ui/Breakpoints';
import { LoadingLine } from '@jetshop/ui/Loading/LoadingLine';
import loadable from '@loadable/component';
import { styled } from 'linaria/react';
import { css } from 'linaria';
import React, { useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router';
import { NavLink, Route, Switch, useParams } from 'react-router-dom';
import { ReactComponent as Caret } from '../../svg/Caret.svg';
import { JournalComponents } from '../ContentEditor/ContentEditorComponents';
import { DynamicPageContentRenderer } from '../ContentEditor/DynamicContentRenderer';
import { GoldBar } from '../Layout/GoldBar';
import MaxWidth from '../Layout/MaxWidth';
import PagePreviewQuery from '../PagePreviewQuery.gql';
import { theme } from '../Theme';
import { FirstJournalPostCard, JournalPostCard } from './JournalPostCard';
import { LatestJournalPosts } from './LatestJournalPosts';
import { ScrollIndicator } from './ScrollIndicator';
import { useInView } from 'react-intersection-observer';

const pageNameStyle = css`
  font-family: ${theme.fonts.secondary};
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 36px;
  text-align: center;
  letter-spacing: 0.03em;
  text-transform: uppercase;

  ${theme.below.lg} {
    font-size: 24px;
    line-height: 24px;
  }
`;

export const Inner = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: 40px 0;
  ${theme.below.lg} {
    margin: 0;
  }
`;

const InnerWrapper = styled('div')`
  ${theme.below.lg} {
    display: flex;
    svg {
      margin-left: 10px;
      width: 20px;
      height: 20px;
      &.open {
        transform: rotate(180deg);
      }
    }
  }
`;

const NavigationWrapper = styled('div')`
  display: flex;
  justify-content: center;
  margin-bottom: 40px;

  ${theme.below.lg} {
    margin-top: 32px;
    margin-bottom: 0;
  }

  .gold-bar {
    :after {
      display: none;
    }
  }

  .active {
    .gold-bar {
      :after {
        display: unset;
        bottom: -5px;
      }
    }
    h3 {
      color: black;
    }
  }
  ${theme.below.lg} {
    flex-direction: column;
    &.closed {
      display: none;
    }
  }
`;

const NavigationItem = styled('h3')`
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  margin: 0 15px;
  letter-spacing: 1px;
  cursor: pointer;
  text-align: center;
  color: #333;

  &:hover {
    color: black;
  }
`;

const NavigationLink = styled(NavLink)`
  text-decoration: none;
  .active {
    .gold-bar {
      :after {
        display: unset;
        bottom: -5px;
      }
    }
    h3 {
      color: black;
    }
  }
`;

const JournalPostWrapper = styled(MaxWidth)`
  max-width: 80rem;
  width: 80%;
  margin: auto;
  ${theme.below.lg} {
    width: 100%;
  }
`;

const JournalPostSection = styled('div')`
  justify-content: flex-start;
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 40px);
  margin: 20px -20px;

  ${theme.below.lg} {
    /* padding-top: 50px; */
    width: 100%;
    margin: 0;
  }
`;

const NotFound = loadable(() => import('../NotFoundPage'), {
  fallback: null
});

const JournalPageHeader = ({ page }) => {
  const subPages = page?.subPages;
  const [navOpen, setNavOpen] = useState(false);
  const { pathname } = useLocation();

  const pageNameElementSwitcher = (path, name) => {
    const slashCount = path.split('/').length - 1;
    if (slashCount > 2) {
      return <h2 className={pageNameStyle}>{name}</h2>;
    }

    return <h1 className={pageNameStyle}>{name}</h1>;
  };
  return (
    <MaxWidth>
      <Inner>
        <GoldBar>
          <Below breakpoint="lg">
            {matches =>
              matches ? (
                <InnerWrapper>
                  {pageNameElementSwitcher(pathname, page?.name)}
                  <Caret
                    className={navOpen ? 'open' : 'closed'}
                    onClick={() => setNavOpen(!navOpen)}
                  />
                </InnerWrapper>
              ) : (
                <>{pageNameElementSwitcher(pathname, page?.name)}</>
              )
            }
          </Below>
        </GoldBar>
      </Inner>
      <NavigationWrapper className={navOpen ? '' : 'closed'}>
        <NavigationLink to={'/inspirasjon-og-raad'} exact={true}>
          <GoldBar>
            <NavigationItem>vis alt</NavigationItem>
          </GoldBar>
        </NavigationLink>
        {subPages &&
          subPages?.map(subPage => (
            <NavigationLink
              key={subPage?.id}
              to={`${subPage?.primaryRoute?.path}`}
            >
              {pathname === subPage.primaryRoute.path && (
                <Helmet title={subPage.name} />
              )}
              <GoldBar>
                <NavigationItem>{subPage?.name}</NavigationItem>
              </GoldBar>
            </NavigationLink>
          ))}
      </NavigationWrapper>
    </MaxWidth>
  );
};

function JournalList({ page }) {
  const allPosts = useSortedPosts(page);
  const restBlogPosts = allPosts.slice(1, allPosts.length);
  const [showed, setShowed] = useState(false);
  const [ref, inView] = useInView();
  if (inView && !showed) setShowed(true);

  return (
    <>
      {page && (
        <>
          <JournalPostWrapper>
            <>
              <FirstJournalPostCard post={allPosts[0]} />
              <JournalPostSection ref={ref}>
                {restBlogPosts &&
                  showed &&
                  restBlogPosts?.map(post => (
                    <React.Fragment key={post.id}>
                      <JournalPostCard list={true} post={post} />
                    </React.Fragment>
                  ))}
              </JournalPostSection>
            </>
          </JournalPostWrapper>
        </>
      )}
    </>
  );
}

const JournalPost = ({ page }) => {
  const params = useParams();
  const allPosts = useSortedPosts(page);
  const lastThree = useMemo(() => {
    const excluded = allPosts?.filter(
      post => post.primaryRoute.slug !== params.name
    );
    return excluded.slice(0, 3);
  }, [params, allPosts]);

  const post = page.subPages
    .find(cat => cat.primaryRoute.slug === params.cat)
    ?.subPages?.find(post => post.primaryRoute.slug === params.name);

  if (!post) {
    return <NotFound />;
  }

  const metaDescription = post?.head?.metaTags?.find(
    tag => tag.name === 'description'
  );

  return (
    <>
      {post ? (
        <>
          <Helmet title={post?.head.title}>
            <meta name="description" content={metaDescription?.content} />
          </Helmet>
          <Below breakpoint="lg">
            {matches =>
              matches ? (
                <ScrollIndicator type={post?.parent?.name} title={post?.name} />
              ) : null
            }
          </Below>
          <DynamicPageContentRenderer
            pageId={post?.id}
            rendererComponents={JournalComponents}
          />
          <LatestJournalPosts category={post?.parent?.name} posts={lastThree} />
        </>
      ) : (
        <LoadingLine
          heightPx={14}
          widthRem={30}
          randomizeWidthBy={10}
          count={10}
          style={{
            marginBottom: '12px'
          }}
        />
      )}
    </>
  );
};

const JournalPage = () => {
  const { data, loading } = useQuery(PagePreviewQuery, {
    variables: {
      id: 53
    },
    errorPolicy: 'ignore'
  });

  const page = data?.page;
  if (loading) return <LoadingLine />;

  return (
    <>
      <Helmet title={page.name} />
      <JournalPageHeader page={page} />
      <Switch>
        <Route path="/inspirasjon-og-raad/:cat/:name">
          <JournalPost page={page} />
        </Route>
        <Route path="/inspirasjon-og-raad/:cat">
          <JournalList page={page} />
        </Route>
        <Route exact path="/inspirasjon-og-raad">
          <JournalList page={page} />
        </Route>
      </Switch>
    </>
  );
};

export const useSortedPosts = page => {
  const params = useParams();

  //Sorting by newest post
  const sorter = (a, b) => {
    if (
      new Date(a?.data?.items[0]?.properties[0]?.value?.value).getTime() <
      new Date(b?.data?.items[0]?.properties[0]?.value?.value).getTime()
    ) {
      return 1;
    }
    if (
      new Date(a?.data?.items[0]?.properties[0]?.value?.value).getTime() >
      new Date(b?.data?.items[0]?.properties[0]?.value?.value).getTime()
    ) {
      return -1;
    }

    return 0;
  };

  const allPosts = useMemo(() => {
    if (page) {
      let tempPosts = [].concat(
        ...page?.subPages?.map((subPage, i) =>
          subPage?.subPages?.map(subSubPage => ({
            ...subSubPage,
            // category: subPage?.name
            category: subPage?.primaryRoute?.slug
          }))
        )
      );

      if (params?.cat) {
        tempPosts = tempPosts?.filter(
          post => post?.category?.toLowerCase() === params?.cat
        );
      }
      return tempPosts.sort(sorter);
    }
    return null;
  }, [params, page]);

  return allPosts;
};

export default JournalPage;
