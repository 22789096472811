import React, { useContext, useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import { useScript } from '../hooks/useScript';
import { styled } from 'linaria/react';
import { theme } from '../Theme';
import { RowTitle } from '../ContentEditor/RowTitle';

const LipscoreReview = styled('div')`
  padding: 3rem;
  width: 100%;
  background-color: #fff;
  ${theme.below.md} {
    padding: 2rem 0.75rem;
  }

  h2 {
    margin-bottom: 40px;
    color: #000;
    text-align: center;
    font-family: ${theme.fonts.secondary};
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 41px; /* 146.429% */

    ${theme.below.lg} {
      margin-bottom: 0;
    }
  }
`;

const LipscoreContent = styled('div')`
  width: 50%;
  margin: auto;

  /* h2 {
    font-family: ${theme.fonts.secondary};
  } */

  .lipscore-testimonial-slider-date {
    margin-left: 30px;
    ${theme.below.lg} {
      margin-left: 10px;
    }
  }
  .lipscore-testimonial-slider-header {
    display: flex;
    justify-content: center;
  }
  .lipscore-service-review-testimonial {
    border: none;
  }
  .lipscore-testimonial-slider-slide:before {
    display: none;
  }
  .lipscore-testimonial-slider-text {
    text-align: center;
  }
  ${theme.below.lg} {
    width: 80%;
  }
  ${theme.below.md} {
    width: 100%;
  }
`;

export const LipscoreCompanyRating = ({ showCustomer = true }) => {
  const { selectedChannel } = useContext(ChannelContext);
  // const language = selectedChannel?.country?.code?.toLowerCase() || 'en'; // Fallback to 'en' if undefined
  const language = 'no';

  const [loaded, error] = useScript(
    `//static.lipscore.com/assets/${language}/lipscore-v1.js`
  );
  const [inited, setInited] = useState(false);
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1
  });

  useEffect(() => {
    if (loaded && inView && typeof window !== 'undefined') {
      if (window.lipscore) {
        if (!inited) {
          window.lipscore.init({
            apiKey: '3b1ebc36ad4d32ebe0e8d322'
          });
          setInited(true);
        } else {
          window.lipscore.initWidgets();
        }
      }
    }
  }, [loaded, inited, inView]);

  if (!loaded || error) return null;

  return (
    <div ref={ref}>
      {showCustomer ? (
        <LipscoreReview>
          <LipscoreContent>
            <h2>Anmeldelser</h2>
            <div
              className="lipscore-service-review-testimonial"
              ls-widget-height="150px"
              ls-widget-width="100%"
            />
          </LipscoreContent>
        </LipscoreReview>
      ) : (
        <div
          className="lipscore-service-review-badge-starred"
          ls-widget-height="85px"
          ls-widget-width="85px"
        ></div>
      )}
    </div>
  );
};

export default LipscoreCompanyRating;
