import React from 'react';
import { Above } from '@jetshop/ui/Breakpoints';
import { styled } from 'linaria/react';
import Image from '@jetshop/ui/Image/Image';
import { Button } from './Button';
import { theme } from '../Theme';

const ImageCategoryBoxWrapper = styled('div')`
  display: flex;

  ${theme.below.lg} {
    flex-direction: column;
  }
`;

const ImageCategoryBoxItemWrapper = styled('div')`
  width: 100%;
  position: relative;

  .text {
    position: absolute;
    top: 30px;
    left: 30px;

    h2 {
      font-family: ${theme.fonts.secondary};
      font-size: 35px;
      font-style: normal;
      font-weight: 400;
      line-height: 41px; /* 117.143% */
    }
    p {
      font-family: ${theme.fonts.primary};
      font-size: 16px;
      font-style: normal;
      font-weight: 330;
      line-height: 24px; /* 150% */
    }

    ${theme.below.lg} {
      top: 20px;
      left: 20px;
    }
  }

  .buttons {
    position: absolute;
    display: flex;
    flex-direction: column;
    right: 30px;
    bottom: 30px;
    row-gap: 10px;

    ${theme.below.lg} {
      right: 20px;
      bottom: 20px;
    }
  }
`;

export const ImageCategoryBoxItem = ({
  imageUrl,
  aspectDesktop,
  aspectMobile,
  color,
  text,
  title,
  btnOneTheme,
  btnOneText,
  btnOneLink,
  btnTwoTheme,
  btnTwoText,
  btnTwoLink
}) => {
  const imageSizes = [1 / 4, 1 / 2, 1];

  const textContent = () => {
    return (
      <div className="text" style={{ color: color?.value }}>
        <h2>{title.value}</h2>
        <p>{text.value}</p>
      </div>
    );
  };

  const buttons = () => {
    return (
      <div className={'buttons'}>
        {btnOneText.value && (
          <Button theme={btnOneTheme} text={btnOneText} link={btnOneLink} />
        )}
        {btnTwoText.value && (
          <Button theme={btnTwoTheme} text={btnTwoText} link={btnTwoLink} />
        )}
      </div>
    );
  };
  return (
    <ImageCategoryBoxItemWrapper>
      <Above breakpoint="lg">
        {matches =>
          matches ? (
            <>
              <Image
                cover={true}
                aspect={aspectDesktop?.value ? aspectDesktop?.value : '720:460'}
                src={imageUrl.value}
                sizes={imageSizes}
              />
              {textContent()}
              {buttons()}
            </>
          ) : (
            <>
              <Image
                cover={true}
                aspect={aspectMobile?.value ? aspectMobile?.value : '336:424'}
                src={imageUrl.value}
                sizes={imageSizes}
              />
              {textContent()}
              {buttons()}
            </>
          )
        }
      </Above>
    </ImageCategoryBoxItemWrapper>
  );
};

export const ImageCategoryBox = ({ children }) => {
  return <ImageCategoryBoxWrapper>{children}</ImageCategoryBoxWrapper>;
};
