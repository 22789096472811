module.exports = {
  default: {
    colors: {
      white: '#FFFFFF',
      black: '#000000',
      main: '#000000',
      grey: '#878787',
      darkerGrey: '#767676',
      lightgrey: '#EBEBEB',
      lightmediumgrey: '#d4d4d4',
      tablegrey: '#F7F7F7',
      beige: '#f5f5dc',
      loadingBar: '#2f80ed',
      blue: '#146DE1',
      red: '#a50000',
      background: '#f7f7f7',
      darkgrey: '#333',
      mediumgrey: '#9a9a9a',
      highlight: '#2f80ed',
      gold: '#B99864',
      darkgold: '#9e7d47',
      breadcrumbgrey: '#b0b0b0',
      newPrimary: '#F1EAE0'
    },
    fontWeights: {
      light: 300,
      regular: 400,
      semibold: 700
    },
    breakpoints: {
      xs: '20rem',
      sm: '40rem',
      md: '50rem',
      lg: '64rem',
      xl: '64rem'
    },
    fontSizes: [
      '12px',
      '14px',
      '16px',
      '20px',
      '24px',
      '32px',
      '48px',
      '64px',
      '72px'
    ],
    space: [
      '0px',
      '8px',
      '16px',
      '24px',
      '32px',
      '40px',
      '48px',
      '56px',
      '64px'
    ],
    fonts: {
      primary: 'Source Sans Pro',
      secondary: 'Silk Serif'
    }
  },
  alt: {
    colors: {
      blue: 'blue',
      main: 'black'
    }
  }
};
