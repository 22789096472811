import React, { useContext, useEffect, useState, useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router';
import { useQuery } from 'react-apollo';
import { Above, Below } from '@jetshop/ui/Breakpoints';
import { ContentRenderer } from '@jetshop/ui/ContentRenderer';
import ModalContext from '@jetshop/ui/Modal/ModalContext';
import { styled } from 'linaria/react';
import debounce from 'lodash.debounce';
import loadable from '@loadable/component';
import useAuth from '@jetshop/core/components/AuthContext/useAuth';
import CampaignBarQuery from '../../ContentEditor/CampaignBarQuery.gql';
import { contentEditorComponents } from '../../ContentEditor/ContentEditorComponents';
import { CustomerBonusQuery } from '../../MyPages/CustomerBonusQuery.gql';
import { CustomerContext } from '../../MyPages/CustomerContext';
import { theme } from '../../Theme';
import { Notifications } from '../Notifications';
import { DesktopHeader } from './DesktopHeader';
import homeCategoriesQuery from './HomeCategoriesQuery.gql';
import { MobileHeader } from './MobileHeader';

// Loadable components
const FlyoutMenu = loadable(() => import('./FlyoutMenu'));
const CartFlyout = loadable(() => import('../../Cart/CartFlyout'));
const FavouritesFlyout = loadable(() =>
  import('../../ProductList/FavouritesFlyout')
);

// Critical CSS directly embedded
const criticalCss = `
  .sticky-container {
    position: sticky;
    height: 90px;
    top: 0px;
    z-index: 20;
    background-color: #fff;
  }

  .header-container {
    height: 90px;
    position: relative;
    z-index: 99;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 20px;
  }

  .header-items-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
  }
`;

const StickyContainer = styled('header')`
  position: sticky;
  height: 90px;
  top: 0px;
  z-index: 20;
  background-color: #fff;

  ${theme.above.lg} {
    transition: 0.1s;
    will-change: auto;

    .desktopmenu {
      font-weight: 500;
      button,
      a {
        color: ${theme.colors.black};
        svg {
          margin-bottom: 5px;
        }
        :hover {
          text-decoration: none;
          color: ${theme.colors.gold};
          svg {
            color: ${theme.colors.gold};
          }
        }
      }
    }
  }

  ${theme.below.lg} {
    .desktopmenu {
      svg {
        color: ${theme.colors.black};
      }
    }

    position: sticky;
    top: 0;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 64px;
  }

  .header-button {
    text-decoration: none;
    color: #666;

    &:hover {
      text-decoration: underline;
      color: #000;
    }
  }
  .header-button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    flex-direction: column;
    cursor: pointer;
    &:hover {
      svg {
        use {
          fill: black;
        }
      }
    }
    svg {
      color: black;
      width: 27px;
      height: 23px;
      margin: 0px;
      use {
        fill: #878787;
      }
    }
    span {
      font-size: 0.75rem;
    }
    .badge-svg-wrapper {
      position: relative;

      .badge {
        position: absolute;
        top: -6px;
        right: -12px;
        ${theme.below.lg} {
          right: -10px;
          width: 16px;
          height: 16px;
          font-size: 10px;
        }
      }
    }
  }
`;

const HeaderContainer = styled('div')`
  height: 90px;
  position: relative;
  z-index: 99;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 20px;

  .logo-wrapper {
    svg {
      height: 36px;
      path {
        fill: black;
      }
      ${theme.below.lg} {
        height: 36px;
      }
      @media (max-width: 1250px) {
        height: 36px;
      }
    }
  }

  ${theme.below.lg} {
    height: auto;
  }
`;

const HeaderItemsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;

  ${theme.below.lg} {
    flex-direction: row;
  }
`;

export const MenuButton = styled('button')`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  background: transparent;
  align-items: center;
  svg {
    height: 18px;
    width: 18px;
  }
  :focus,
  :active,
  :hover {
    outline: none;
    opacity: 0.8;
  }
  label {
    margin-top: 3px;
  }
`;

export const IconContainer = styled('span')`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  margin: 0;
`;

const CampaignBarWrap = styled('div')`
  z-index: 4;
  ${theme.below.lg} {
    position: sticky;
    z-index: 9;
    height: 34px;
  }
`;

const HeaderCampaignBar = React.memo(() => {
  const { data } = useQuery(CampaignBarQuery, {
    variables: { id: 166 }
  });
  const items = data?.category?.data?.items;

  return (
    <>
      {items && (
        <CampaignBarWrap id="topBar">
          <ContentRenderer items={items} components={contentEditorComponents} />
        </CampaignBarWrap>
      )}
    </>
  );
});

const CustomerDataHandler = React.memo(() => {
  const { setDiscount } = useContext(CustomerContext);
  const { data, loading } = useQuery(CustomerBonusQuery);

  useEffect(() => {
    if (data) {
      const bonusGroup = data?.customer?.externalAttributes?.find(
        externalAttribute => externalAttribute?.name === 'oipDiscount'
      );

      setDiscount(bonusGroup?.value);
    }
  }, [data, loading, setDiscount]);

  return null;
});

const TopHeader = React.memo(({ searchOpen, setSearchOpen, categories }) => {
  const { loggedIn } = useAuth();

  return (
    <HeaderContainer className="header-container">
      {loggedIn && <CustomerDataHandler />}
      <HeaderItemsContainer className="header-items-container">
        <Above breakpoint="xl">
          {matches =>
            matches && (
              <DesktopHeader
                data={categories.data}
                searchOpen={searchOpen}
                setSearchOpen={setSearchOpen}
                loggedIn={loggedIn}
              />
            )
          }
        </Above>
        <Below breakpoint="xl">
          {matches =>
            matches && (
              <MobileHeader
                data={categories.data}
                searchOpen={searchOpen}
                setSearchOpen={setSearchOpen}
                loggedIn={loggedIn}
              />
            )
          }
        </Below>
      </HeaderItemsContainer>
    </HeaderContainer>
  );
});

export default function Header() {
  const location = useLocation();
  const drawerOpen = useContext(ModalContext);
  const [searchOpen, setSearchOpen] = useState(false);
  const [isStartPage, setStartPage] = useState('');
  const [isTop, setTop] = useState(true);

  const handleScroll = useCallback(
    debounce(() => {
      setTop(window.pageYOffset < 70);
    }, 30),
    [setTop]
  );

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    setStartPage(location?.pathname === '/');
  }, [location?.pathname]);

  const result = useQuery(homeCategoriesQuery, { variables: { levels: 1 } });

  return (
    <>
      <Helmet>
        <style>{criticalCss}</style>
      </Helmet>
      <HeaderCampaignBar />
      <StickyContainer
        id="stickyHeader"
        // className={cx(
        //   isStartPage && isTop && !drawerOpen.isOpen && 'startpageHeader',
        //   searchOpen && 'searchOpen'
        // )}
      >
        <TopHeader
          searchOpen={searchOpen}
          setSearchOpen={setSearchOpen}
          categories={result}
        />
      </StickyContainer>
      <Notifications />
      <FlyoutMenu left={true} data={result.data} belowHeader={true} />
      <CartFlyout />
      <FavouritesFlyout />
    </>
  );
}
