import React, { useState } from 'react';
import { styled } from 'linaria/react';
import { theme } from '../Theme';
import Image from '@jetshop/ui/Image/Image';
import { setVariableFromProp } from '../../utils/HelpFunctions';
import { Text } from './Text';
import { Above } from '@jetshop/ui/Breakpoints';
import { useInView } from 'react-intersection-observer';

const LargeIconUspRowContainer = styled('div')`
  padding: 60px;
  height: auto;

  ${theme.below.lg} {
    padding: 0 20px;
  }
`;
const LargeIconUspRowWrapper = styled('div')`
  display: grid;
  grid-template-columns: repeat(2, 50%);
  grid-column-gap: 40px;
  grid-row-gap: 60px;
  max-width: 60rem;
  margin: auto;

  h3 {
    font-family: ${theme.fonts.secondary};
  }

  p {
    color: ${props => props.textColor};
  }

  ${theme.below.lg} {
    padding: 40px 0;
    grid-template-columns: repeat(1, 100%);
    grid-row-gap: 20px;
  }
`;

export const LargeIconUspRow = ({ children, textColor, backgroundColor }) => {
  return (
    <LargeIconUspRowContainer style={{ background: backgroundColor?.value }}>
      <LargeIconUspRowWrapper textColor={textColor?.value}>
        {children}
      </LargeIconUspRowWrapper>
    </LargeIconUspRowContainer>
  );
};

const LargeIconUspItemWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  position: relative;
  height: auto;
  justify-content: center;
  align-items: center;

  ${theme.below.lg} {
    align-items: flex-start;
  }
`;

const IconWrapper = styled('div')`
  width: 80px;
  height: auto;
`;

const TextWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  padding: 0 20px;

  .text-component {
    h3 {
      text-align: left;
      margin-bottom: 5px;
      color: #000;
      font-family: ${theme.fonts.secondary};
      font-size: 22px;
      font-style: normal;
      font-weight: 500;
      line-height: 41px; /* 186.364% */
      text-transform: none;

      ${theme.below.lg} {
        line-height: 150%;
      }
    }
    p {
      text-align: left;
      text-transform: none;
      font-family: ${theme.fonts.primary};
      font-size: 13px;
      font-style: normal;
      font-weight: 390;
      line-height: 22px; /* 169.231% */
    }
  }
`;

export const LargeIconUspItem = ({ imageUrl, title, text, hideInMobile }) => {
  const [showed, setShowed] = useState(false);
  const [ref, inView] = useInView();
  if (inView && !showed) setShowed(true);
  const mobileHide = setVariableFromProp(hideInMobile);
  const imgUrl = setVariableFromProp(imageUrl);

  const comp = () => {
    return (
      <LargeIconUspItemWrapper ref={ref}>
        <IconWrapper>
          <Image src={imgUrl} aspect={'1:1'} sizes={'100'} cover />
        </IconWrapper>
        <TextWrapper>
          <Text size={'SMALLTITLE'} text={title} color={'#000000'} />
          <Text size={'TEXT'} text={text} color={'#000000'} />
        </TextWrapper>
      </LargeIconUspItemWrapper>
    );
  };

  return (
    <Above breakpoint="lg">
      {matches => (matches ? <>{comp()}</> : <>{mobileHide ? null : comp()}</>)}
    </Above>
  );
};
