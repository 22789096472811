import React, { useState } from 'react';
import { styled } from 'linaria/react';
import { theme } from '../Theme';
import { useInView } from 'react-intersection-observer';

const Title = styled('h2')`
  color: #000;
  text-align: center;
  font-family: ${theme.fonts.secondary};
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 41px; /* 146.429% */
`;

const PreviewArticleRowWrapper = styled('div')`
  display: flex;
  margin: 20px 0;

  .postwrapper {
    padding: 0;
  }
  .post-text-wrapper {
    padding: 0;
    padding-top: 16px;

    ${theme.below.lg} {
      padding-bottom: 40px;
    }
  }

  h3 {
    color: #000;
    text-align: center;
    font-family: ${theme.fonts.primary};
    font-size: 15px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 22px !important;
    text-transform: capitalize !important;
  }

  ${theme.below.lg} {
    flex-direction: column;
  }
`;

export const PreviewArticleRow = ({ title, children }) => {
  const [showed, setShowed] = useState(false);
  const [ref, inView] = useInView();
  if (inView && !showed) setShowed(true);

  return (
    <div ref={ref}>
      <Title>{title?.value}</Title>
      <PreviewArticleRowWrapper>{children}</PreviewArticleRowWrapper>
    </div>
  );
};
