import { styled } from 'linaria/react';
import React from 'react';
import { useCookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import { ReactComponent as Cross } from '../../svg/Cross.svg';
import { theme } from '../Theme';

const CampaignBarContainer = styled('div')`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* background: ${props => props.backgroundcolor}; */
  background: ${theme.colors.newPrimary};

  ${theme.below.lg} {
    padding: 0 8px;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
  }
`;
const CampaignBarItemContainer = styled('div')`
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 30px;
  padding: 0 0.8rem;

  ${theme.below.lg} {
    min-height: 34px;
    max-height: 34px;
    display: flex;

    padding: 0;
    flex: none;
    width: auto;
  }

  a,
  p {
    font-style: normal;
    font-size: 12px;
    font-family: ${theme.fonts.secondary};
    text-decoration: none;
    /* color: ${props => props.textcolor}; */
    color: #2E1D1D;
    ${theme.below.lg} {
      font-size: 10px;
    }
  }
`;

const CloseBtn = styled('div')`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

  .icon-wrapper {
    margin-right: 11px;
    background: white;
    padding: 4px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 14px;
    height: 14px;
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.3);

    &:hover {
      cursor: pointer;
    }

    svg {
      width: 9px !important;
      height: auto;
      use {
        fill: #666666;
      }
    }
  }
`;

export const CampaignBarItem = ({ link, text, textColor }) => {
  return (
    <CampaignBarItemContainer
      textcolor={textColor?.value}
      className="campaign-bar-item"
    >
      {link?.value?.length > 0 ? (
        <Link
          to={link?.value}
          dangerouslySetInnerHTML={{
            __html: text?.value
          }}
        />
      ) : (
        text?.value && (
          <p
            dangerouslySetInnerHTML={{
              __html: text?.value
            }}
          />
        )
      )}
    </CampaignBarItemContainer>
  );
};

export const CampaignBar = ({
  closeCampaignBarOption,
  children,
  backgroundColor
}) => {
  const [cookies, setCookie] = useCookies();
  const hideCampaignBar = cookies?.hideCampaignBar;
  const showCloseBtn = closeCampaignBarOption?.value === 'YES';

  const handleCloseClick = () => {
    setCookie('hideCampaignBar', true, [{ path: '/' }]);
  };

  return (
    <>
      {!hideCampaignBar && (
        <CampaignBarContainer
          id="campaign-bar"
          backgroundcolor={backgroundColor?.value}
          parts={children?.length ?? 1}
        >
          {showCloseBtn && (
            <CloseBtn
              onClick={() => {
                handleCloseClick();
              }}
            >
              <div className={'icon-wrapper'}>
                <Cross />
              </div>
            </CloseBtn>
          )}

          {children}
        </CampaignBarContainer>
      )}
    </>
  );
};
